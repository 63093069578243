import axios from 'axios';
import React, { useState } from 'react'
import { baseUrl } from '../../services/Services';

export default function SuccessPayment() {
    const [items, setItems] = useState<any>(localStorage.getItem("cardElement") ? localStorage.getItem("cardElement") : "");
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [isOk, setIsOk] = useState(false);


    
    const listItems = JSON.parse(items)

    // console.log("first", listItems)

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        let datas: any = listItems;
        try {
            const response = await axios.post(`${baseUrl}detail/paiement`, {
                
                reservationLogement:{

                    logement:{
            
                        id: listItems.reservationLogement && listItems.reservationLogement.logement.id,
                        reference: listItems.reservationLogement && listItems.reservationLogement.logement.reference,
                    },
                    dateDebut: listItems.reservationLogement && listItems.reservationLogement.dateDebut,
                    dateFin: listItems.reservationLogement && listItems.reservationLogement.dateFin
                },
                paiement: {
                    
                    montant: listItems.paiement && listItems.paiement.montant,
                    description: listItems.paiement && listItems.paiement.description,
                    token: listItems.paiement && listItems.paiement.token,
                    payment_method: listItems.paiement && listItems.paiement.payment_method,
                    numeroCard:"",
                    curency: listItems.paiement && listItems.paiement.curency,
                },
                user:{

                    email: listItems.user && listItems.user.email,
                    nom: listItems.user && listItems.user.nom,
                    prenom: listItems.user && listItems.user.prenom,
                    adresse: listItems.user && listItems.user.adresse,
                    password: "FRFDJKDKJD",
                    telephone: listItems.user && listItems.user.phone
                }
                
            });


            if(response.data.success) {
                // console.log("Successful payment");
                // window.location.href = "http://localhost:3000/";
                // window.location.href = "https://fo.haodymadagascar.com";
                // localStorage.removeItem("cardElement");
                setIsProcessing(false);
                setIsOk(true)
            }

        } catch (error) {
            // console.log("Error", error);
            setError(true)
            setIsOk(false)
            setIsProcessing(false);

        }
    }
  return (
    <div className="containerOK">
        <div className="row">

            <div className="col-md-6 mx-auto mt-5">
                
                <div className="paymentCont">
                    {
                        error && (
                            <div className="alert alert-danger" role="alert">
                                Votre enregistrement a été echoué, veuillez essayer SVP!
                            </div>
                        )
                    }
                    {
                        isOk && (
                            <div className="alert alert-success" role="alert">
                                Votre enregistrement a été bien effectué!
                            </div>
                        )
                    }
                    <div className="payment">
                        <div className="payment_header">
                            <div className="check"><i className="fa fa-check" aria-hidden="true"></i></div>
                            </div>
                            <div className="content">
                            <h1>Payment Success !</h1>
                            <p>Felicitation, votre paiement a été effectué avec success!. </p>
                            <p>Pour finaliser votre reservation, il faut cliquer sur le bouton en bas! </p>

                            <button onClick={(e: any)=> handleSubmit(e)}>
                                {
                                    isProcessing ? (
                                        <p>En cours</p>
                                    ):(
                                        <p>Finaliser votre reservation</p>
                                    )
                                }
                                
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}
