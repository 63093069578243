import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DetailGuidVComponent } from '../../Components/GuideComponent/DetailGuidVComponent'
import { ArticleContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import ReactLoading from 'react-loading';

export const DetailGuidVoyage = () => {
    const [article, setArticle] = useState<any>([]);
    const [loading, setLoading] = useState<boolean | any>(false);
    const {id} = useParams<any>();


    useEffect(() => {

        setLoading(true);

        const getArticle = async() => {
            try{
                await axios.get(`${baseUrl}article/${id}`).then((response: any) => {
                
                    setArticle(response.data.data.rows);
                    setLoading(false);

                })
            }catch{
                setLoading(false);
                // console.log("Tsy mety");
            }
        }
        getArticle();

    }, []);

    return (
        <div className="_container" style={{minHeight: "55vh"}}>
            <ArticleContext.Provider value={article}>

                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ):(
                        <DetailGuidVComponent/>
                    )
                }

            </ArticleContext.Provider>
        </div>
    )
}
