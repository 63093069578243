import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DetailLogementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { LogementType } from '../../utils/Interface/LogementType';
import { DetailLogementComponent } from '../../Components/DetailLogementComponent/DetailLogementComponent'
import axios from 'axios';
import ReactLoading from 'react-loading';

interface Props {
    devise: any,
    valeur: any,
    currency: any
}
export const DetailLogement: React.FC<Props> =({devise, valeur, currency}) => {
    const [logement, setLogement] = useState<LogementType | any>([]);
    const { id }= useParams<any>();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState<boolean | any>(false);

    /**
   * @author Freddy Michel
   * @description get one data
   */

    useEffect(() => {

        setLoading(true);
        const getLogement= async () => {
            await axios.get(`${baseUrl}logement/${id}`)
                .then((response: any) => {
                    setLogement(response.data.data.rows);
                    setLoading(false);
                })
                .catch((error: any)=> {
                    setLoading(false);
                    // console.log(error);
                })
        }
        getLogement();
    }, [id]);

    return (
        <div className="_container">
            {
                loading ? (
                    <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                ):(
                    <DetailLogementContext.Provider value={logement}>
                        <DetailLogementComponent
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            devise={devise}
                            valeur={valeur}
                            currency={currency}
                        />
                    </DetailLogementContext.Provider>
                )
            }
        </div>
    )
}
