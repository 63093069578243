import React, { useContext } from 'react'
import { ArticleContext } from '../../Context/AppContext'
import parse from 'html-react-parser';
import { baseUrl } from '../../services/Services';
import im1 from '../../assets/guide/im1.jpeg';
import im2 from '../../assets/guide/im2.jpeg';
import im3 from '../../assets/guide/im3.jpeg';
import im4 from '../../assets/guide/im4.jpeg';
import im5 from '../../assets/guide/im5.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y, Navigation, Pagination } from 'swiper';
import { ClientRouter } from '../../utils/Route/Route';
import { Link } from 'react-router-dom';

export const DetailGuidVComponent = () => {
    const article: any = useContext(ArticleContext)
    
    return (
        <div>
             <div className="row mb-4">
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        {/* <h3 className='d-none'><a href="#">HAODY MADAGASCAR</a></h3> */}
                    </div>
                   <div>
                   <Link style={{color: "#000", fontWeight:'bold', fontSize:'1.2rem'}} to={`${ClientRouter.home}`}>Trouver un logement de vacances</Link>
                   </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="card" style={{width: "100%", height: "350px"}}>
                    <div className='articleHead' >
                        <img className='imgFond' src={fond} alt="" />
                    </div>
                </div>
            </div> */}
            <div className="row">
            <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                autoplay
                slidesPerView={1}
                style={{height: '400px', objectFit:'cover'}}
                navigation
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 50
                    },
        
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50
                    },
        
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50
                    },
        
                    
                  }}
                >
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}}  src={im1} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im2} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im3} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im4} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im5} alt="" className="_fiara1" />
                </SwiperSlide>
            </Swiper>
                
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mt-4">
                        
                        <div className="row">
                            
                            <div className="col-12 col-md-4"></div>
                            <div className="col-12 col-md-4">
                                {
                                    article.files && article.files.map((data: any) => (
                                        <img style={{width: "100%", margin: 'auto'}} src={`${baseUrl}${data.path}`} alt={data.name} />
                                    ))
                                }
                            </div>
                            <div className="col-12 col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-8">
                    <div className="">
                        {/* <div className="card-content">

                            <h1 style={{textTransform: "uppercase", fontWeight: "600", textAlign: "center"}}>{article.categorie && article.categorie.designation}</h1>

                        </div> */}
                        <div className="card-body">
                            {/* <h4>{article.title}</h4> */}
                            <p style={{textAlign:"justify"}}>
                                {parse(`${article.contentHtml}`)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-2"></div>
            </div>
        </div>
    )
}
