import axios from 'axios';
import React, { useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { baseUrl } from '../../services/Services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
export const ContactComponents: React.FC = () => {

    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [error, setError] = useState<boolean | any>(false);
    const [isProssessing, setIsProcessing] = useState(false);


    const sendContact = (e: any) => {
        e.preventDefault();


        if(nom === "" && prenom === "" && email === "" && phone === "" && message === "") {

            toast.error("Les champs sont vides");
            setError(true);

        }else{
            setIsProcessing(true);
            axios.post(`${baseUrl}api/proprietaire`, {
                nom: nom,
                prenom: prenom,
                // sujet: subject,
                mail: email,
                telephone: phone,
                message: message
              }).then((response: any) => {
                // console.log(response.data.success)
                if(response.data.success === true){
                    toast.success("Message bien envoyé")
                    // console.log(response);
                    // window.location.reload();
                    setNom("");
                    setPhone("");
                    setPrenom("");
                    setEmail("");
                    setMessage("");
                    // setSubject("");
                    setError(false);
                    setIsProcessing(false);
                }
          
              }).catch((error: any) => {
                setError(true);
                toast.error("Échec de l'envoi email");
                setIsProcessing(false);
                // console.log("Message: ", error);
          
            });
        }
    }

    return (
        <div className='row'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="dark"
                toastStyle={error ? { backgroundColor: "red" } : { backgroundColor: "green"}}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
                {/* Same as */}
            <ToastContainer />
            <div className="col-12 col-md-8 m-auto">
                {/* {
                    error && (
                        <div className="card alert alert-danger">
                            
                        </div>
                    )
                } */}
                
                <div className="card">
                    <div className="card-header">
                        <h3>Contactez-nous</h3>
                    </div>
                    <div className="card-body">
                        <div className='_date_rsa row mb-4'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="name">Nom</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <input value={nom} onChange={(e) => setNom(e.target.value)}  type="text" placeholder='Nom' className="w-100 form-control" />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="phone">Prénom</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <input value={prenom} onChange={(e) => setPrenom(e.target.value)} type="text" placeholder='Prénoms' className="w-100 form-control" />
                                </div>
                            </div>
                        </div>


                        <div className='_date_rsa row mb-4'>

                            <div className='col-12 col-md-6'>
                                <label htmlFor="phone">Téléphone</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type="phone" placeholder='Numero téléphone' className="w-100 form-control" />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="name">Adresse email</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <input value={email} onChange={(e) => setEmail(e.target.value)}  type="mail" placeholder='Adresse email' className="w-100 form-control" />
                                </div>
                            </div>
                            
                        </div>

                        {/* <div className="_date_rsa row mb-4">
                            <div className='col-12'>
                                <label htmlFor="phone">Sujet</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" placeholder='Objet du message' className="w-100 form-control" />
                                </div>
                            </div>
                        </div> */}

                        <div className='_date_rsa row'>
                            <div className='col-12 col-md'>
                                <label htmlFor="name">Message</label><br />
                                <div className="input-group w-100 input-group-lg">
                                    
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={5} ></textarea>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    

                    <div className="card-footer p-4">
                        <button disabled={isProssessing} onClick={(e) => sendContact(e)} className="w-100 m-auto btn btn-lg _btnShop">
                            {
                                isProssessing ? (
                                    <p className='m-0'>Chargement...</p>
                                ):(
                                    <>
                                        <AiOutlineSend size={35} className="mr-4"/>
                                        Envoyer
                                    </>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
