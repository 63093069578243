import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ClientRouter } from '../../utils/Route/Route';
import logo from "../../assets/imgs/logo.png";
import axios from 'axios';
import { baseUrl } from '../../services/Services';
import ReactGa from "react-ga4";
interface Props {
    devise: any,
    setDevise: any,
    setValeur: any,
    valeur: any,
    currency: any,
    setCurrency: any,
    searchText: any,
    setSearchText: any
}
export const NavBarClient: React.FC<Props> = ({
    devise,
    valeur,
    setDevise,
    setValeur,
    currency,
    setCurrency,
    searchText,
    setSearchText,
}) => {

    const [allDevise, setAllDevise] = useState<any[]>([]);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [size, setSize] = useState<number | any>(0);

    // const [onDevise, setOneDevise] = useState<any>();

    

    const handleChange = (e: any) => {
        setDevise(e.target.value);
        // window.location.reload();
        setValeur(devise);
    }

    useEffect(() => {
        const getDevise = () => {
            axios.get(`${baseUrl}devise`).then((data: any) => {
                if(data) {
                    setAllDevise(data.data.data.rows);
                }
            })
        }

        getDevise();
    }, []);

    useEffect(() => {

        
        const updateSize = () => {
            if (window.innerWidth < 992) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            } 
            setSize(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    let dev = allDevise.filter( (data: any) => {
        return data.devise === devise
    });

    dev.map((val: any) => {
        if(val) {
            setValeur(val.valeurEnAriary);
            setCurrency(val.code)
        }
    })
        
    const url = useLocation();

    const guideLink = () => {
        ReactGa.event({
            category: `Guide de voyage`,
            action: 'Click sur le bouton guide de voyage'
        });
        window.open(ClientRouter.guideVoyage, '_blank');
        // window.location.href = `logement/${id}/details_${ref}, '_blank'`
    }

    const contactezNous = () => {
        ReactGa.event({
            category: `Nous contactez`,
            action: 'Click sur le bouton nous contactez'
        });
        window.open(ClientRouter.contactezNous, '_blank');
        // window.location.href = `logement/${id}/details_${ref}, '_blank'`
    }
    return (
        <>
        {
            url.pathname !=="/foire-aux-questions" && url.pathname !=="/mentions-legales" && url.pathname !=="/a-propos" && (
                <nav className="navbar fixed-top navbar-expand-lg navbar-light mb-4" style={{paddingLeft: "5rem", paddingRight: "5rem"}}>
                    <Link className="navbar-brand p-0" to={ClientRouter.home}>
                        <img src={logo} className="logo_" alt="" />
                    </Link>
                    <div>
                        {
                            isMobile && (
                                <label htmlFor="ok" className=' mr-4'>
                                    <select value={devise} onChange={(e)=> handleChange(e)} className='form-contro currencySelect_' name="pets" id="pet-select">
                                    
                                        {
                                            allDevise && allDevise.map((data: any) => (
                                                <option key={data.id} value={data.devise}>{data.code}</option>
                                            ))
                                        }
                                        
                                    </select>
                                </label>
                            )
                        }
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                {
                                    url.pathname === "/guide-de-voyage" ? (
                                        <input value={searchText} onChange={(e: any) => setSearchText(e.target.value)} type="text" placeholder='Recherche...' name="" className='form-control' id="" />
                                    ):(
                                        <div>
                                            {/* 
                                            </select> */}
                                            
                                            <div onClick={guideLink} className="btn btn-light">
                                                <h2 className='btnGuideV'>Guide de voyage</h2>
                                            </div>
                                            &nbsp;
                                            <div onClick={contactezNous} className="btn _contactBtn">
                                                Louez votre maison
                                            </div>
                                            &nbsp;
                                            {
                                                !isMobile && (
                                                    <label htmlFor="ok">
                                                        <select value={devise} onChange={(e)=> handleChange(e)} className='form-control currencySelect_' name="pets" id="pet-select">
                                                        
                                                            {
                                                                allDevise && allDevise.map((data: any) => (
                                                                    <option key={data.id} value={data.devise}>{data.code}</option>
                                                                ))
                                                            }
                                                            
                                                        </select>
                                                    </label>
                                                )
                                            }
                                            
                                        </div>
                                    )
                                }
                                
                            </li>
                        </ul>
                    </div>

                </nav>
            )
        }
        
        </>
    )
}
