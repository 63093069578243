import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { LogementContext } from '../../../../Context/AppContext';
import { LogementType } from '../../../../utils/Interface/LogementType';

import { GrPowerReset } from 'react-icons/gr';
import imgs from '../../../../assets/imgs/img.png';
import { baseUrl } from '../../../../services/Services';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Swiper, SwiperSlide } from 'swiper/react';

import ReactGA from "react-ga4";
import parse from 'html-react-parser';

// Import Swiper styles
import 'swiper/css';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Form, InputGroup } from 'react-bootstrap';
import { BlockText } from '../BlockText/BlockText';
import Select from 'react-select';

interface Props {
    devise: any,
    valeur: any
}

export const CardLogement: React.FC<Props> = ({ devise, valeur }) => {

    const logements = useContext(LogementContext);
    const [size, setSize] = useState<number | any>(0);
    const [item, setItem] = useState<number | any>(1);


    const [equipements, setEquipements] = useState<{ id: number, designation: string }[]>([]);

    const [loading, setLoading] = useState<boolean | any>(false);

    const [logemenstFilter, setLogementFilter] = useState<any>([]);
    const [logemenstFilterEquipement, setLogementFilterEquipement] = useState<any>([]);
    const [dataTemp, setDataTemp] = useState<any>([]);

    const [ville, setVille] = useState<any>("");
    const [dateDebut, setDateDebut] = useState<any>("");
    const [dateFin, setDateFin] = useState<any>("");
    const [nbrMax, setNbrMax] = useState<any>("");

    const [allVille, setAllVille] = useState<any>([]);

    const [allText, setAllText] = useState<any>([]);


    const [devises, setDevise] = useState<any>("");
    const [currencys, setCurrency] = useState("");

    const currencyUnit: any = localStorage.getItem("currency");
    const deviseName: any = localStorage.getItem("devise");


    const [reservDate, setReservDate] = useState<any>([]);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<number[]>([]);
    
      
    

    const sectionRef = useRef<HTMLElement | any>(null);

    const handleSelectChange = (selectedOptions: any[]) => {

        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedEquipmentIds(selectedIds);

    };

    
    const filterByEquipments = logemenstFilter.filter((item: any) => {
        if (selectedEquipmentIds.length === 0) {
            return true;
        }
        const equipmentIds = item.equipements && item.equipements.flatMap((e: any) => e.id);
        return selectedEquipmentIds.every((id) => equipmentIds && equipmentIds.includes(id));
    });



    const handler = (event: any) => {
        const value = event;

        const filtrer = logemenstFilter.filter((property: { equipements: any[]; }) => {
            return value.every((val: { value: any; }) => {
              return property.equipements.some(
                (equipment: { id: number; }) => val.value === equipment && equipment.id
              );
            });
        });


        if (value.length > 0) {
            setDataTemp(filtrer);

        } else {
            setDataTemp(logemenstFilter);

        }

    }


    let formatter: any;

    if (devise === "Ariary") {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: "MGA",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencyUnit,
        });
    }


    useEffect(() => {
        

        setLoading(true);
        const dev = devise;

        if (dev) {

            setDevise(dev);

        }

        const getAllEquipements = async () => {
            try {
                await axios.get(`${baseUrl}equipement`).then(response => {

                    setEquipements(response.data.data.rows);
                    setLoading(false);

                })
            } catch {
                setLoading(false);
                toast.error("Pas de donnée");
            }
        }

        const getAllVileDipo = async () => {
            try {
                await axios.get(`${baseUrl}ville`).then(res => {
                    setAllVille(res.data.data.rows)
                })
            } catch {
                toast.error("Pas de donnée");
            }
        }

        const allData = async () => {
            try {
                await axios.get(`${baseUrl}parametre-texte`).then(response => {

                    if (response) {

                        setAllText(response.data.data.rows);

                    }

                });
            } catch {
                toast.error("Pas de donnée");
            }
        }
        allData();


        getAllEquipements();
        getAllVileDipo();

    }, []);

    useEffect(() => {

        
        const updateSize = () => {
            if (window.innerWidth < 600) {
                setItem(1);
            } else if (window.innerWidth > 600 && window.innerWidth < 1024) {
                setItem(2);
            } else if (window.innerWidth > 1024 && window.innerWidth < 1200) {
                setItem(3);
            } else if (window.innerWidth > 1200) {
                setItem(4);
            }
            setSize(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);


    function isDateInRange(dateToCheck: any) {
        for (let i = 0; i < reservDate.length; i++) {
            const startDate = new Date(reservDate[i].dateDebut);
            const endDate = new Date(reservDate[i].dateFin);
            const date = new Date(dateToCheck);
            if (date >= startDate && date <= endDate && reservDate[i].status.designation === "a_venir") {
                return true;
            }
        }
        return false;
    }

    


    const filterData = (e: any) => {
        

        e.preventDefault();
        const params = {
            ville: ville,
            dateDebut: moment(dateDebut).format("YYYY-MM-DD"),
            dateFin: moment(dateFin).format("YYYY-MM-DD"),
            nombreMax: nbrMax
        }

        if (ville === "") {
            toast.error("Le champs ville est obligatoir!");
        }

        if (ville !== "" && dateDebut !== "" && dateFin !== "" && nbrMax !== "") {
            setIsFilter(true);

            ReactGA.event({
                category: `Filtre par ${ville} - ${dateDebut} - ${dateFin} - ${nbrMax}`,
                action: 'Click boutton c\'est partie'
            });

            axios.get(`${baseUrl}logement/cestparti`, {
                params
            }).then(res => {

                sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                
                setLogementFilter(res.data.data.rows);
                setDataTemp(res.data.data.rows);

                setVille("");
                setDateDebut("");
                setDateFin("");
                setNbrMax("");
                // toast.success("Il y a de donnée");
            }).catch(err => {
                setIsFilter(false);
                toast.error("Il y a une erreur ou il n'y a pas de donnée");
            })


        } else {
            setIsFilter(false);
            toast.error("Tous les champs sont obligatoirs!");

        }
    }



    const dateNow: any = new Date();


    let date: any = dateNow.setDate(dateNow.getDate() + 7);

    const dateMarina: any = moment(date).format("YYYY-MM-DD");



    let startDateSelected: any = new Date(dateMarina);


    const dateSelect: any = moment(startDateSelected).format("YYYY-MM-DD");

    const dateFinSelect: any = dateDebut !== null ? dateDebut : dateSelect;

    const detailLogement = (id: number) => {
        ReactGA.event({
            category: `Voir detail logement}`,
            action: 'Click detail logement'
        });
        window.open('logement/'+id+'/details');
        // window.location.href = `logement/${id}/details_${ref}, '_blank'`
    }

    const resetDashboard = () => {
        setLogementFilter([]);
        setIsFilter(false);
    }

    return (
        <div className='row'>
            <div style={{ width: "100%" }}>
                <div className="row">
                    <div className="col-12">
                        <div className="card" style={{ width: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <Form.Group className='col-12 col-md-2'>
                                        <Form.Label>Où</Form.Label>

                                        <InputGroup hasValidation>
                                            <Form.Select value={ville} onChange={(e: any) => setVille(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                                                <option value="">Selectionner une ville</option>
                                                {
                                                    allVille.map((ville: any) => (
                                                        <option key={ville.id} value={ville.id}>{ville.designation}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a email adress.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    {/* <div className="col-12 col-md-3">
                                        
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Ou</label>
                                            <input type="text" value={ville} onChange={(e: any)=> setVille(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Ou" />
                                        </div>
                                    </div> */}
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Arrivée</label>
                                            <input type="date" min={dateSelect} value={dateDebut} onChange={(e: any) => setDateDebut(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Arrivée" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Départ</label>
                                            <input type="date" min={dateFinSelect} value={dateFin} onChange={(e: any) => setDateFin(e.target.value)} className="form-control calendar-black" id="exampleInputPassword1" placeholder="Départ" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Nombre de voyageurs (+3ans)</label>
                                            <input type="number" value={nbrMax} onChange={(e: any) => setNbrMax(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Nombre de voyageurs" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 boxBtn__">
                                        <button onClick={(e) => filterData(e)} className='btn __contactBtnCp' style={{color: "#4c3e8b"}}>C'est parti !</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>



                <div className='row mt-4 mb-4'>
                    {
                        allText.filter((el: any) => el.status && el.status.designation === "active").map((data: any) => (

                            <div key={data.id} className="col-12 col-md-4">
                                <div className="block_ mt-4">
                                    <h3>{data.titre}</h3>
                                </div>
                                <div className="content-box">
                                    <p>
                                        {data.valeur}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div ref={sectionRef} className="blocks_ mt-4" style={{ textAlign: "left" }}>
                    {
                        logemenstFilter && logemenstFilter.length > 0 ? (
                            <h3 style={{color: "#4c3e8b", fontSize:"120%"}}>Résultats ({logemenstFilter.length})</h3>
                        ) : (
                            <div>
                                {
                                    isFilter ? (
                                        <h3 style={{color: "#4c3e8b", fontSize:"120%"}}>Résultats ({logemenstFilter.length})</h3>
                                    ):(
                                        <h3 style={{color: "#4c3e8b", fontSize:"120%"}}>À la une</h3>
                                    )
                                }
                            </div>
                        )

                    }
                </div>
                <hr />
            </div>
            {
                logemenstFilter && logemenstFilter.length > 0 ? (
                    <>
                        <label htmlFor="">Filtrer par</label><br />
                        <div className='row mb-4 pl-2' style={{ width: "100%" }}>

                            {/* <Select className='w-50' isMulti options={equipements.map(({ id, designation }) => ({
                                value: id,
                                label: designation

                            }))} onChange={()=> handler} /> */}

                            <Select
                                className='w-50'
                                options={equipements.map(({ id, designation }) => ({
                                    value: id,
                                    label: designation

                                }))}
                                isMulti
                                onChange={(e: any)=> handleSelectChange(e)}
                                // value={options.filter((option) =>
                                // selectedEquipmentIds.includes(option.value)
                                // )}
                            />
                            <button onClick={resetDashboard} className='btn btn-light'>
                                Réinitialiser
                            </button>

                        </div>
                        <br />
                        <br />
                        <div className='row' style={{ width: "100%" }}>

                            {

                                filterByEquipments.map((item: any) => (

                                    <div className="col-12 col-lg-3" key={item.id}>
                                        <div className="card" onClick={()=> detailLogement(item.id)}>
                                            <div className="card-header">
                                                <div className="d-flex cardFooter">
                                                    <div className="userProfile">
                                                        <div className="_userName float-left">
                                                            {/* <h6 className="adress_">Adresse: {item.adresse}</h6> */}
                                                            {/* <h6 className="adress_">Personne max: {item.nombrePersonneMax}</h6>
                                                            <h6 className="adress_">Nombre pièce: {item.nombrePieceTotal}</h6> */}
                                                            <label className="ville_">{item.quartier && item.quartier.designation}</label><br />
                                                            <label className="ville_">{item.quartier && item.quartier.ville.designation}</label><br />
                                                            {/* <label className="lName_">{item.designation.slice(0, 10)}</label> */}
                                                        </div>
                                                    </div>
                                                    <div className="btnComment float-right">
                                                        {
                                                            <h3 className="_price">{formatter.format(item.loyer / valeur)}</h3>
                                                        }
                                                        {/* {
                                                            devise && devise === "Euro" && <h3 className="_price">{formatter.format(item.loyer / valeur)}</h3>
                                                        }
                                                        {
                                                            devise && devise === "Dollar" && <h3 className="_price">{formatter.format(item.loyer / valeur)}</h3>
                                                        } */}
                                                        {/* <h3 className="_price">{item.loyer} Euro</h3> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0" style={{ padding: "0px" }}>
                                                {
                                                    item.files && item.files.length > 0 ? (
                                                        item.files.filter((img: any) => img.isCover === true).map((imgs: any, index: any) => (
                                                            <div onClick={()=> detailLogement(item.id)} key={index} className="w-100 m-auto btn btn-lg _btnShop">
                                                                <div className="cardImg">
                                                                    <img src={`${baseUrl}${imgs.path}`} alt={imgs.name} className="_fiara2" />
                                                                </div>
                                                            </div>
                                                        ))
                                                        
                                                    ) : (
                                                        <div onClick={()=> detailLogement(item.id)} className="w-100 m-auto btn btn-lg _btnShop">
                                                                <div className="cardImg">
                                                                    
                                                                <img src={imgs} alt="" className="_fiara1" />

                                                            </div>
                                                        </div>
                                                    )

                                                }
                                            </div>
                                            <div className="card-footer" style={{height: "120px"}}>
                                                <label className="">{parse(`${item.description.length > 100 ? item.description.slice(0, 140) + '...' : item.description}`)}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : (
                    <div className='col-12 col-md-6 text-center m-auto p-4'>
                        {
                            isFilter && (
                                <h4>Aucun résultat pour cette recherche. Nous vous invitons à modifier vos dates pour découvrir les logements disponibles.</h4>
                            )
                        }
                    </div>
                )
            }

            {
                !isFilter && (
                    <div className='row' style={{ width: "100%" }}>
                        {
                            logements && logements.length > 0 ? (
                                logements.map((logement: LogementType) => (
                                    
                                    <div className="col-12 col-lg-3" key={logement.id}>
                                            <div className="" onClick={()=> detailLogement(logement.id)}>
                                                <div className="card  mb-4">
                                                    <div className="card-header">
                                                        <div className="d-flex cardFooter">
                                                            <div className="userProfile">
                                                                <div className="_userName float-left">
                                                                    <label className="ville_">{logement.quartier && logement.quartier.ville.designation}</label><br />
                                                                    {/* <h6 className="adress_">{logement.adresse}</h6> */}
                                                                    <label className="ville_">{logement.quartier && logement.quartier.designation}</label><br />
                                                                    {/* <label className="lName_">{logement.designation.slice(0, 10)}</label> */}
                                                                </div>
                                                            </div>
                                                            <div className="btnComment float-right">
                                                                <h3 className="_price">{formatter.format(logement.loyer / valeur)}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-0" style={{ padding: "0px" }}>
                                                        {
                                                            logement.files.length > 0 ? (
                                                                logement.files.filter((img: any) => img.isCover === true).map((imgs: any, index: any) => (
                                                                    <div onClick={()=> detailLogement(logement.id)} key={index} className="w-100 m-auto btn btn-lg _btnShop">
                                                                        <div className="cardImg">
                                                                            <img src={`${baseUrl}${imgs && imgs.path}`} alt={imgs && imgs.name} className="_fiara2" />
                                                                        </div>
                                                                    </div>
                                                                ))

                                                            ) : (
                                                                <div onClick={()=> detailLogement(logement.id)} className="w-100 m-auto btn btn-lg _btnShop">
                                                                    <div className="cardImg">
                                                                        <img src={imgs} alt="sary" className="_fiara1" />
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    </div>


                                                    <div className="card-footer pt-4" style={{height: "120px"}}>
                                                        {parse(`${logement.description.length >100 ? logement.description.slice(0, 140) + '...' : logement.description}`)}
                                                        {/* <label className="">{logement.description.length >100 ? logement.description.slice(0, 100) + '...' : logement.description}</label> */}

                                                    </div>

                                                </div>
                                            </div>
                                        
                                    </div>
                                    
                                ))
                            ) : (
                                <div className='card' style={{ width: "100%" }}>
                                    <div className="card-body text-center">
                                        <h4>Pas de données</h4>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ) 
            }


        </div>
    )
}
