import React from 'react';
import styles from './MentionLegal.module.css';

const MentionLegal = () => {
	return (
		<div className={styles.mentionLegal}>
			<h1 className={styles.title}>
				MENTIONS LÉGALES
			</h1>

			<div className={styles.content}>
				<h2 className={styles.subtitle}>HAODY MADAGASCAR</h2>
				<br />
				<div className={styles.txt}>
					<p>
						Société à Responsabilité Limitée, au capital de <strong>1 000 000 Ariary</strong>
					</p>
					<p>
						Immatriculée au R.C.S. de Tananarive sous le numéro <strong>2023 B 00020</strong>
					</p>
				</div>

				<div className={styles.infoCEO}>
					<p>Dirigeant : RAKOTOARISOA ONJALALAINA</p>
					
					<p>Téléphone : +261 (0)32 19 052 97 / +261 (0)38 05 503 30</p>
					<p>E-mail : <strong>contact@haodymadagascar.com</strong></p>
				</div>

				<div className={styles.infoCEO}>
					<p>Site hébergé par :</p>
					<p>
						Simafri Madagascar
					</p>
					<p>
						<span>Lot 95 Belle-Vue Ambatobe</span><br />
						<span>101 Antananarivo</span><br />
						<span>Madagascar</span>
					</p>
				</div>
			</div>
		</div>
	)
};

export default MentionLegal;
