import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { LogementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { LogementType } from '../../utils/Interface/LogementType';
import { DashboardComponent } from '../../Components/DashboardComponent/DashboardComponent';
import ReactLoading from 'react-loading';
import { toast, ToastContainer } from 'react-toastify';

interface Props {
  devise: any,
  valeur: any
}
export const DashboardPage: React.FC<Props> = ({devise, valeur}) => {
  const [logements, setLogements] = useState<LogementType | any>([]);

  

  const [loading, setLoading] = useState<boolean | any>(false);

  /**
   * @author Freddy Michel
   * @description Get all logements lits
   */
  useEffect(() => {
    //Set loading on fetch data
    setLoading(true);

    //execut request to fetch data in api
    const getAllLogements = async() => {
      try{
        await axios.get(`${baseUrl}logement`).then(response => {
          
          setLogements(response.data.data.rows);
          setLoading(false);

        })
      }catch{
        setLoading(false);
        toast.error("Erreur de chargement des données! Veuillez réessayer SVP!");
      }
    }
    getAllLogements();

  }, []);
  
  /**
   * @author Freddy Michel
   * @description get all data
   */
  


  
  return (
    <div className="_container">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="dark"
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          {/* Same as */}
      <ToastContainer />
      
      {
        loading ? (
          <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
        ):(
          <LogementContext.Provider value={logements}>
            <DashboardComponent devise={devise} valeur={valeur}/>
          </LogementContext.Provider>
        )
      }
      
    </div>
  )
}
