import React, { useEffect, useState } from 'react';
import './App.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { NavBarClient } from './Layouts/NavBar/NavBarClient';
import { ClientRouter } from './utils/Route/Route';
import { DashboardPage } from './Pages/DashboardPage/DashboardPage';
import { DetailLogement } from './Pages/DetailLogementPage/DetailLogement';
import { ContactPage } from './Pages/ContactPages/ContactPage';
import { GuideVoyage } from './Pages/GuideVoyage/GuideVoyage';
import Footer from './Layouts/Footer/Footer';
import SuccessPayment from './Pages/SuccessPayment/SuccessPayment';
import ConditionGeneral from './Pages/ConditionGeneral/ConditionGeneral';
import {DetailGuidVoyage} from './Pages/GuideVoyage/DetailGuidVoyage';
import axios from 'axios';
import { baseUrl } from './services/Services';
import Faq from './Pages/FAQ/Faq';
import { MentionLegal } from './Pages/MentionLegal';
import { AboutPage } from './Pages/AboutPage';

import ReactGA from "react-ga4";


function App() {
    const [devise, setDevise] = useState<any>(localStorage.getItem("devise") ? localStorage.getItem("devise") : "Euro");
    const [valeur, setValeur] = useState<any>(localStorage.getItem("valDev") ? localStorage.getItem("valDev") : 1);
    const [currency, setCurrency] = useState<any>(localStorage.getItem("currency") ? localStorage.getItem("currency") : "EUR");
    const [searchText, setSearchText] = useState<string | any>('');

    const [articles, setArticles] = useState<any>([]);
    const [loading, setLoading] = useState<boolean | any>(false);

    const TRACKING_ID = 'G-XL7LE96ZF3';


    useEffect(() => {

        ReactGA.initialize(TRACKING_ID);
       
        ReactGA.send({hitType: 'pageview', page: `${window.location.pathname}`})

        // ReactGa.pageview(`${window.location.pathname + window.location.search}`);

        setLoading(true);

        const getAllLogements = async() => {
        try{
            await axios.get(`${baseUrl}article`).then(response => {
            
            setArticles(response.data.data.rows);
            setLoading(false);

            })
        }catch{
            setLoading(false);
            // console.log("Tsy mety");
        }
        }
        getAllLogements();

    }, []);

    const dataFilter = articles.filter((name: any) => {
      return (
          name.categorie && name.categorie.designation.toLowerCase().includes(searchText.toLowerCase()) ||
          name && name.title.toLowerCase().includes(searchText.toLowerCase())
      )
    });

    
    localStorage.setItem("devise", devise);
    localStorage.setItem("devVal", valeur);
    localStorage.setItem("currency", currency);
    // console.log("Devise", devise, 'Valeur', valeur, "Currency", currency)
    return (
      <BrowserRouter>
          <NavBarClient 
            currency={currency} 
            setCurrency={setCurrency} 
            devise={devise} 
            setDevise={setDevise} 
            setValeur={setValeur} 
            searchText={searchText}
            setSearchText={setSearchText}
            valeur={valeur}
          />
          <Routes>

            <Route path={ClientRouter.home} element={<DashboardPage devise={devise} valeur={valeur}/>} />
            <Route path={ClientRouter.detailLogement} element={<DetailLogement currency={currency} devise={devise} valeur={valeur} />} />
            <Route path={ClientRouter.contactezNous} element={<ContactPage/>} />
            <Route path={ClientRouter.guideVoyage} element={<GuideVoyage loading={loading} articles={dataFilter} />} />
            <Route path={ClientRouter.completed} element={<SuccessPayment/>}/>
            <Route path={ClientRouter.conditionG} element={<ConditionGeneral/>}/>
            <Route path={ClientRouter.faq} element={<Faq/>}/>
            <Route path={ClientRouter.mention} element={<MentionLegal/>}/>
            <Route path={ClientRouter.about} element={<AboutPage/>}/>
            <Route path={`${ClientRouter.detailGuideVoyage}/:id/details/:title`} element={<DetailGuidVoyage/>}/>

          </Routes>
          <Footer/>
          {/* <CookieConsent
            location="bottom"
            buttonText="Accepter"
            declineButtonText="Annuler"
            cookieName="haodyMadagascarCookie"
            style={{ 
              background: "#2B373B", 
              height: "auto", 
              display: "block",
              width: "100%",
              margin: "auto",
              paddingBottom: "1rem"
            }}
            buttonStyle={{ color: "#4e503b" }}
            buttonWrapperClasses='btn-coockies'
            enableDeclineButton
          >
            <h2>Accepter les cookies</h2>
            <p style={{textAlign: "justify"}}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis ex porro facere iure, veniam neque sequi ducimus explicabo voluptas provident eos quod tempora minus optio dolores officiis iste corrupti aliquam quaerat corporis distinctio modi error. Earum dolorum quos illo enim voluptatibus quia mollitia? Omnis asperiores placeat impedit vero earum exercitationem laudantium doloribus repudiandae hic deleniti et, nulla aliquid error nam aperiam explicabo consequuntur optio cumque delectus praesentium in voluptatem ad, harum nostrum? Eum harum voluptatem iusto architecto, minima sunt tenetur recusandae minus ipsa beatae consectetur illum explicabo magnam animi ut impedit eos deleniti, dolorum ipsum vero eligendi. Officia aliquam libero at facilis voluptatum dolores rem, recusandae mollitia quo voluptas repellat quaerat minima provident alias accusantium ut id consequatur optio neque dolore nesciunt corrupti itaque sint consequuntur? Quo aliquid asperiores laudantium! Vel minus dolores tempore totam reiciendis, fuga ipsam mollitia dicta iusto blanditiis impedit sapiente maiores. Tenetur dolorem sit recusandae ducimus?
            </p>
          </CookieConsent> */}
        </BrowserRouter>
    );
}

export default App;
