export const ClientRouter = {
    home: '/',
    detailLogement: '/logement/:id/details',
    contactezNous: '/contactez-nous',
    guideVoyage: '/guide-de-voyage',
    detailGuideVoyage: '/detail-guide-de-voyage',
    completed: '/completed-payment',
    conditionG: '/condition-general-de-vente',
    faq: '/foire-aux-questions',
    mention: '/mentions-legales',
    about: '/a-propos'

}