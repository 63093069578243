import React from 'react'
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../services/Services';
import imgs from '../../../../assets/imgs/img.png'
import parse from 'html-react-parser';
import { ClientRouter } from '../../../../utils/Route/Route';



interface Props {
    itemList: any
}
export const ListArticle: React.FC<Props> = ({itemList}) => {

    const options = {
        replace: (domNode: any) => {
          if (domNode.attribs && domNode.attribs.class === 'remove') {
            return <></>;
          }
        },
    };

   
    return (
        <div className='row'>
            
            {
                itemList.length > 0 ? (
                    itemList.map((article: any) => (
                    
                        <div className="col-12 col-md-3" key={article.id}>
                            <div className="card  mb-4" style={{minHeight: '500px'}}>
                                {/* <div className="card-header">
                                    <div className="d-flex cardFooter">
                                        <div className="userProfile">
                                            <div className="_userName float-left">
                                                <h6 className="adress_">{article.adresse}</h6>
                                                <label className="ville_">{article && article.title}</label>
                                            </div>
                                        </div>
                                        <div className="btnComment float-right">
                                            
                                        </div>
                                    </div>
                                </div> */}
    
                                
                                
                                <div className="card-body p-0" style={{padding: "0px", display: 'block'}}>
                                {
                                    article.files.length > 0 ? (
                                        <div className="cardImg w-full">
                                            <img src={`${baseUrl}${article.files[0].path}`} alt={article.files[0].name} className="w-full" />
                                        </div> 
                                    ):(
                                        <div className="cardImg w-full">
                                            <img src={imgs} alt="" className="w-full" />
                                        </div>
                                    )
                                        
                                }
                                </div>
                                {/* <div className='text-center'>
                                    <h1 className="articleTitle">{article && article.title}</h1>
                                </div> */}
                                <div className='px-4'>
                                    <p className=''>
                                        {parse(article.contentHtml.length > 200 ? article.contentHtml.slice(0, 200) + ' ...' : article.contentHtml, options)}
                                    </p>
                                </div>

                                <div className="w-auto m-auto py-4 d-flex">
                                    <Link to={`${ClientRouter.detailGuideVoyage}/${article.id}/details`} target="_blank" className="w-100 m-auto btn btn-lg _btnShop1">
                                        Lire la suite
                                    </Link>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    ))
                ):(
                    <div className='card' style={{width: "100%"}}>
                        <div className="card-body text-center">
                            <h4>Pas de données</h4>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
