import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { ImFacebook } from 'react-icons/im';
import {BsInstagram} from 'react-icons/bs';
import { AiFillMail } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../services/Services';
import { ClientRouter } from '../../utils/Route/Route';

export default function Footer(props : any) {

  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [message, setMessage] = useState<any>("");

  const [isProssessing, setIsProcessing] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const url = useLocation();


  // api/front/envoye-mail

  const sendContact = (e: any) => {
    e.preventDefault();
    
    if(name === "" || email === "" || message === "") {
      toast.error("Les champs sont vides!")
    }else{
      setIsProcessing(true);
      axios.post(`${baseUrl}envoie-mail`, {
        sujet: name,
        adresseEmail: email,
        message: message
      }).then(res => {
        if(res) {
          toast.success("Email envoyer avec success");
          handleClose();
          setName("");
          setMessage("");
          setEmail("");
          setIsProcessing(false);
        }
      }).catch(err => {
        toast.error("Erreur lors de l'envoie email")
        setIsProcessing(false);
      });

    }
  }
  
  return (
    <>
      {
        url.pathname !=="/foire-aux-questions" && url.pathname !=="/mentions-legales" && url.pathname !=="/a-propos" && (
          <div className='container_'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
                {/* Same as */}
            <ToastContainer />
            <div className="row">
              
              <div className="col-12 col-md-4">
                <ul className="listFooter_ pl-4">
                  <li><a href="https://conditionsgenerales.haodymadagascar.com/" target="_blank">Conditions Générales de Service</a></li>
                  <li><Link to={`${ClientRouter.about}`} target="_blank">QUI SOMMES-NOUS</Link></li>
                  <li><Link to={`${ClientRouter.mention}`} target="_blank">Mentions Légales</Link></li>
                  <li><Link to={`${ClientRouter.faq}`} target="_blank">FAQs</Link></li>
                </ul>
              </div>

              <div className="col-12 col-md-4">
                <div>
                  <h4 className='nous-C text-center'>Nous contacter</h4>
                  <div className="listFooterr_">
                    <div className='logoFooterr_'>
                    
                      <Link to="#" className='logoFF' onClick={handleShow}>
                        <AiFillMail size="1.2em" className='_logoFooter'/>
                      </Link>
                    </div>
                    <div className='logoFooterr_'>
                      <Link className='logoFF' to="#">
                        <BsInstagram size="1.2em" className='_logoFooter'/>
                      </Link>
                    </div>
                    &nbsp;
                    <div className='logoFooterr_'>
                      <a target='_blank' href="https://web.facebook.com/profile.php?id=100094659726554&mibextid=ZbWKwL&_rdc=1&_rdr">
                        <ImFacebook size="1.2em" className='_logoFooter'/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 text-center">
                <h4 className='nous-C text-center'>Assistance Voyageurs</h4>
                <h3 className="nous-C"><strong>+261 32 19 052 98</strong></h3>
              </div>

            </div>


            <Modal {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={show}
              onHide={handleClose}
            >
              {/* <Modal.Header closeButton>
                <Modal.Title>Envoi email</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Sujet</label>
                    <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Sujet" />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Votre adresse email</label>
                    <input type="mail" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Adresse email" />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Message</label>
                    <textarea className="form-control" value={message} onChange={(e)=>setMessage(e.target.value)} id="exampleFormControlTextarea1" rows={3} ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>

                <button disabled={isProssessing} type="button" onClick={sendContact} className="btn btn-primary">
                  {
                    isProssessing ? (
                      <>Chargement...</>
                    ):(
                      <>Envoyer</>
                    )
                  }
                </button>
              
              </Modal.Footer>
            </Modal>
          </div>
        )
      }
    </>
  )
}
