import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DetailLogementContext } from '../../Context/AppContext'
import image from '../../assets/imgs/img.png';
import { AiFillBackward, AiOutlineShoppingCart } from 'react-icons/ai';
import moment from 'moment';
import { baseUrl } from '../../services/Services';
import { Elements } from '@stripe/react-stripe-js';
import {CheckoutForm} from './Components/CheckoutForm';
import { loadStripe } from "@stripe/stripe-js"
import { toast } from 'react-toastify';


import { addDays, eachDayOfInterval } from 'date-fns';
import * as locale from 'date-fns/locale';

import 'react-day-picker/dist/style.css';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';

import parse from 'html-react-parser';



// const PUBLIC_KEY = "pk_live_51MGhC0DuAOiWFt44u6Hd8Ku0eZDB8S7FOMupEY4WGu4RyZNm9gzLCoUa4bIOesSia6ll2v0ibqkplbYE3M4Zr0LE003PjAnRFm"
// const CLIENT_SECRET = "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"
const PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`;
const CLIENT_SECRET = `${process.env.REACT_APP_STRIPE_SECRET_KEY}`;

const stripePromise = loadStripe(PUBLIC_KEY);

const options: any = {
    // passing the client secret obtained from the server
    clientSecret: CLIENT_SECRET,
};

interface Props {
    startDate: Date | any,
    endDate: Date | any,
    setStartDate: any,
    setEndDate: any,
    devise: any,
    valeur: any,
    currency: any
}
export const DetailLogementComponent: React.FC<Props> = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    devise,
    valeur,
    currency
}) => {
    const logement = useContext<any>(DetailLogementContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [selected, setSelected] = React.useState<Date>();
    const [pourcentage, setPourcentage] = useState<number>(10);
    const [publicKey, setPublicKey] = useState<any>("")
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [resDate, setResDate] = useState(logement && logement.reservations);
    const [fs, setFS] = useState<any>([]);
    const [dateSelected, setDateSelected] = useState<any>([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: 'selection'
        }
    ]);

    const [email, setEmail] = useState<any>("");
    const [nom, setNom] = useState<any>("");
    const [prenom, setPrenom] = useState<any>("");
    const [adresse, setAdresse] = useState<any>("");
    const [phone, setPhone] = useState<any>("");
    const [desc, setDesc] = useState<any>("");
    const [nbrVoyageur, setNbrVoyageur] = useState<any>("");

    const [show, setShow] = useState(false);
    const [showDate, setShowDate] = useState<boolean>(false);

    const handleClose = () => setShow(false);

    const handleShowDate = (e: Event) => {
        e.preventDefault();
        setShowDate(!showDate);
    }

    const handleShow = () => {
        if(email !== "" && nom !== "" && prenom !== "" && adresse !== "" && phone !== "" && nbrVoyageur !== "") {

            setShow(true);

        }else {

            toast.warning("Tous les champs sont vide");

        }
    }


    useEffect(() => {
        moment.locale('us-US');

        const getFS = async() => {
            await axios.get(`${baseUrl}frais-service`).then((res: any) => {
                if(res) {
                    setFS(res.data.data.rows[0].valeur)
                }
            }).catch((err: any) => {
                console.log("error")
            })
        }
        getFS();
    }, []);

    const reference = logement && logement.reference;


    const debut: any = moment(dateSelected[0].startDate ? dateSelected[0].startDate : new Date());
    const fin: any = moment(dateSelected[0].endDate ? dateSelected[0].endDate : new Date());

    const diff = fin.diff(debut);

    const diffDuration = moment.duration(diff);

    // const day = diffDuration.days();

    
    const diffTime = Math.abs(fin - debut);
    const day = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    const totalPaye = logement.loyer ? logement.loyer * day : 0;


    const dateNow: any = new Date();

    let date: any = dateNow.setDate(dateNow.getDate() + 7);

    const dateMarina: any = moment(date).format("YYYY-MM-DD");


    let startDateSelected: any = new Date(dateMarina);

    
    
    const fraisService: any = (totalPaye * fs)/100;
    
    
    const dateSelect: any = moment(startDateSelected).format("YYYY-MM-DD");
    
    const dateFinSelect: any = startDate !== null ? startDate : dateSelect;

    const currencyUnit: any = localStorage.getItem("currency");
    const deviseName: any = localStorage.getItem("devise");

    const [reservDate, setReservDate] = useState<any>([]);


    const netAPAyer = totalPaye + fraisService;    


    useEffect(() => {
        setReservDate(logement.reservations)

        const getConfig = async() => {
            try{
                await axios.get(`${baseUrl}detail/paiement/config`)
                .then((res: any) => {
                    setPublicKey(res.data.data.rows.send)
                });
            }catch{
                toast.error("Erreur de chargement des données! Veuillez réessayer SVP!");
            }
        }
        getConfig()

        
    }, []);


    const disabledDatesLists = useMemo(() => {
        let dates: Date[] = [];
    
        reservDate && reservDate.forEach((reservation: any) => {
          const range = eachDayOfInterval({
            start: new Date(reservation.dateDebut),
            end: new Date(reservation.dateFin)
          });
    
          dates = [...dates, ...range];
        });
    
        return dates;
    }, [reservDate]);


    function isDateInRange(dateToCheck: any) {
        for (let i = 0; i < reservDate.length; i++) {
            const startDate = new Date(reservDate[i].dateDebut);
            const endDate = new Date(reservDate[i].dateFin);
            const date = new Date(dateToCheck);
            if (date >= startDate && date <= endDate && reservDate[i].status.designation === "a_venir") {
                return true;
            }
        }
        return false;
    }

    const goToStep2 = () => {

    
        setStep2(true);
        setStep1(false);
        
    }

    const goToStep1 = () => {

        setStep1(true);
        setStep2(false);
        
    }

    let formatter: any;
    
    if (devise === "Ariary") {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: "MGA",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencyUnit,
        });
    }

    return (
        <div className='row'>
            
            <div className="col-12 col-md-6">
                <div className="card">
                    <div className="card-header">
                        {/* <label className="adress_">{logement.adresse ? logement.adresse : "--"}</label> &nbsp; | &nbsp; */}
                        <label className="ville_">{logement.quartier ? logement.quartier.ville.designation : "--"}</label> &nbsp; | &nbsp;

                        <label className="ville_">{logement.quartier ? logement.quartier.designation : "--"}</label> &nbsp; | &nbsp;
                        <label className="ville__">{formatter.format(logement.loyer ? logement.loyer / valeur : 0)} / nuit</label>
                        
                        &nbsp; | &nbsp;
                       
                        <label className="adress_">{logement.nombrePersonneMax} personnes</label> &nbsp; | &nbsp;
                        <label className="adress_">{logement.nombrePieceTotal} pièces</label>
                    </div>
                    <div className='p-4'>
                        <h3 className="adress_">Liste des équipements</h3>
                        <hr />
                        <div className='raw'>
                        {
                            logement.equipements && logement.equipements.map((log: any, index: any) => {
                                return <span className='badge bg-secondary mx-1' key={index}>{log && log.designation}</span>
                            })
                        }
                        </div>
                        <hr />
                    </div>
                    
                    <div className="card-body">
                    
                        <Carousel style={{height: "auto"}} variant="dark">
                            {
                                logement.files && logement.files.length > 0 ? (
                                    logement.files.map((logement: any) => (

                                        <Carousel.Item interval={3000} key={logement.id}>

                                            <img style={{height: "100%", maxHeight: '600px', maxWidth:'1000px', width: 'auto'}} src={`${baseUrl}${logement.path}`} alt={logement.name} className="_fiara2" />
                                            
                                        </Carousel.Item>
                                            
                                        
                                    ))
                                    
                                ):(
                                    <Carousel.Item interval={3000}>
                                        
                                        <img style={{height: "100%", width: '100%'}} src={image} alt="" className="_fiara1" />
                                        
                                    </Carousel.Item>
                                    
                                )
                                    
                            }
                        </Carousel>
                    </div>

                    <div className="card-footer">
                        <p className='_description'>
                            {parse(`${logement.description}`)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="card">
                    <div className="card-header">
                        {
                            step1 ? (
                                <h3>Réservation du logement</h3>
                            ):(
                                <h3>Informations sur le voyageur</h3>
                            )
                        }

                    </div>
                    {
                        step1 && (
                            <div>
                                
                                <div className="card-body">

                                    {/* <div>
                                        <input onFocus={(e: any) => (handleShowDate(e))} type="text" name="date" id="date" className='form-control input-lg' />
                                    </div> */}
                                    <div className='_date_rsa row'>

                                    
                                        <DateRangePicker
                                            onChange={item => setDateSelected([item.selection])}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            className='calendarStyle'
                                            minDate={addDays(new Date(), 7)}
                                            disabledDates={disabledDatesLists}
                                            ranges={dateSelected}
                                            direction="horizontal"
                                            locale={locale.fr}
                                            
                                        
                                        />
                                        
                                        {/* <div className='col-12 col-md-6'>
                                            
                                            <label htmlFor="startDate">Date d'arrivé</label><br />
                                            <div className="input-group w-100 input-group-lg">
                                                
                                                <input min={dateSelect} value={startDate} onChange={handleChangeDate} type="date" className="w-100 form-control" />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <label htmlFor="endDate">Date de départ</label><br />
                                            <div className="input-group w-100 input-group-lg">
                                                
                                                <input min={dateFinSelect} value={endDate} onChange={(e: any) => setEndDate(e.target.value)} disabled={!isDisabled} type="date" className="w-100 form-control" />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="contenu">

                                    <table className="table">
                                        
                                        <tbody>
                                            <tr>
                                                <td><label className='totalP_' htmlFor="">Nuitée </label></td>
                                                <td><b style={{float: "right"}}>{day}</b></td>
                                            </tr>
                                            <tr>
                                                <td><label className='totalP_' htmlFor="">Tarif  </label></td>
                                                <td>
                                                    <b style={{float: "right"}}>{formatter.format(logement.loyer ? logement.loyer / valeur : 0)}/Nuit</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label className='totalP_' htmlFor="">Frais de services  </label></td>
                                                <td>
                                                    <b style={{float: "right"}}>{endDate === "" ? 0 : formatter.format(fraisService / valeur)}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label className='totalP_' style={{fontSize: "150%"}} htmlFor="">Total </label></td>
                                                <td>
                                                    <b style={{float: "right", fontSize: "150%"}}>{endDate === "" ? 0 : formatter.format(netAPAyer / valeur)}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        )
                    }
                    {
                        step2 && (
                            <div className="card-body">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        logement = {logement.id}
                                        dateEnd={moment(dateSelected[0].endDate).format("YYYY-MM-DD")}
                                        startDate={moment(dateSelected[0].startDate).format("YYYY-MM-DD")}
                                        amount={netAPAyer}
                                        devise={devise}
                                        valeur={valeur}
                                        currency={currency}
                                        reference={reference}
                                        show={show}
                                        setShow={setShow}
                                        nom={nom}
                                        setNom={setNom}
                                        prenom={prenom}
                                        setPrenom={setPrenom}
                                        email={email}
                                        setEmail={setEmail}
                                        adresse={adresse}
                                        setAdresse={setAdresse}
                                        phone={phone}
                                        setPhone={setPhone}
                                        desc={desc}
                                        setDesc={setDesc}
                                        nbrVoyageur={nbrVoyageur}
                                        setNbrVoyageur={setNbrVoyageur}
                                        handleShow={handleShow}
                                        handleClose={handleClose}
                                    />
                                </Elements>
                            </div>
                        )
                    }

                    <div className="contenu">
                    </div>

                    <div className="card-footer p-4">
                        {
                            step1 && (
                                <button onClick={() => goToStep2()} className="w-100 m-auto btn btn-lg _btnShop">
                                    <AiOutlineShoppingCart size={35} className="mr-4"/>
                                    Suivant
                                </button>
                            )
                        }

{
                            step2 && (
                                <div className="mt-4 d-flex">
                                    <button onClick={() => goToStep1()} className="w-auto m-auto btn btn-lg _btnShop mr-2">
                                        <AiFillBackward  size={35} className="mr-4"/>
                                        Retour
                                    </button>
                                    
                                    <button className="w-auto m-auto btn btn-lg _btnShop ml-2" onClick={(e) => handleShow()}>
                                        <AiOutlineShoppingCart  size={35} className="mr-4"/>
                                        Réserver maintenant
                                                
                                    </button>
                                </div>
                            )
                        }

                       
                    </div>
                </div>
            </div>
        </div>
    )
}
